import { useState } from "react";

export default function BlockBlastSolver() {
  // Create a fresh empty board each time
  const [gameBoard, setGameBoard] = useState(() =>
    Array(8)
      .fill()
      .map(() => Array(8).fill(0))
  );

  // Initialize with three empty piece editors
  const [pieceEditors, setPieceEditors] = useState([
    Array(5)
      .fill()
      .map(() => Array(5).fill(0)),
    Array(5)
      .fill()
      .map(() => Array(5).fill(0)),
    Array(5)
      .fill()
      .map(() => Array(5).fill(0)),
  ]);

  const [analysisSteps, setAnalysisSteps] = useState([]);
  const [solution, setSolution] = useState(null);
  const [isSolving, setIsSolving] = useState(false);
  const [showingSolution, setShowingSolution] = useState(false);

  // Helper function to get the appropriate color classes
  const getColorClasses = (index) => {
    switch (index) {
      case 0:
        return {
          text: "text-red-600",
          bg: "bg-red-600",
          highlight: "bg-red-400",
          border: "border-red-500",
          button: "bg-red-500 hover:bg-red-600",
          overlay: "bg-red-600/30",
        };
      case 1:
        return {
          text: "text-blue-600",
          bg: "bg-blue-600",
          highlight: "bg-blue-400",
          border: "border-blue-500",
          button: "bg-blue-500 hover:bg-blue-600",
          overlay: "bg-blue-600/30",
        };
      case 2:
        return {
          text: "text-purple-600",
          bg: "bg-purple-600",
          highlight: "bg-purple-400",
          border: "border-purple-500",
          button: "bg-purple-500 hover:bg-purple-600",
          overlay: "bg-purple-600/30",
        };
      default:
        return {
          text: "text-gray-600",
          bg: "bg-gray-600",
          highlight: "bg-gray-400",
          border: "border-gray-500",
          button: "bg-gray-500 hover:bg-gray-600",
          overlay: "bg-gray-600/30",
        };
    }
  };

  // Toggle a cell on the game board
  const toggleBoardCell = (row, col) => {
    if (showingSolution) return; // Prevent editing during solution visualization

    // Use the functional form of setState to ensure we're working with the latest state
    setGameBoard((prevBoard) => {
      // Create a completely new 2D array for the board
      const newBoard = [];
      for (let r = 0; r < prevBoard.length; r++) {
        newBoard[r] = [...prevBoard[r]];
      }

      // Toggle the clicked cell
      newBoard[row][col] = newBoard[row][col] === 1 ? 0 : 1;

      return newBoard;
    });

    // Clear any existing solution when the board changes
    if (solution) {
      setSolution(null);
      setShowingSolution(false);
    }
  };

  // Reset the game board to empty
  const resetBoard = () => {
    // Create a completely new 2D array
    const freshEmptyBoard = Array(8)
      .fill()
      .map(() => Array(8).fill(0));

    setGameBoard(freshEmptyBoard);
    setSolution(null);
    setShowingSolution(false);
    setAnalysisSteps([]);
  };

  // Toggle a cell in the piece editor
  const togglePieceEditorCell = (editorIndex, row, col) => {
    setPieceEditors((prevEditors) => {
      const newEditors = [...prevEditors];
      newEditors[editorIndex] = newEditors[editorIndex].map((r) => [...r]);
      newEditors[editorIndex][row][col] = newEditors[editorIndex][row][col] === 1 ? 0 : 1;
      return newEditors;
    });

    // Clear solution if we change the pieces
    if (solution) {
      setSolution(null);
      setShowingSolution(false);
    }
  };

  // Clear a piece editor
  const clearPieceEditor = (editorIndex) => {
    setPieceEditors((prevEditors) => {
      const newEditors = [...prevEditors];
      newEditors[editorIndex] = Array(5)
        .fill()
        .map(() => Array(5).fill(0));
      return newEditors;
    });

    // Clear solution if we change the pieces
    if (solution) {
      setSolution(null);
      setShowingSolution(false);
    }
  };

  // Trim a piece to remove empty rows and columns
  const trimPiece = (pieceGrid) => {
    // Find bounds
    let minRow = pieceGrid.length - 1;
    let maxRow = 0;
    let minCol = pieceGrid[0].length - 1;
    let maxCol = 0;

    // Find the boundaries of the non-empty cells
    for (let row = 0; row < pieceGrid.length; row++) {
      for (let col = 0; col < pieceGrid[row].length; col++) {
        if (pieceGrid[row][col] === 1) {
          minRow = Math.min(minRow, row);
          maxRow = Math.max(maxRow, row);
          minCol = Math.min(minCol, col);
          maxCol = Math.max(maxCol, col);
        }
      }
    }

    // If the piece is empty (no cells set)
    if (minRow > maxRow || minCol > maxCol) {
      return [[0]]; // Return a single empty cell
    }

    // Extract the trimmed piece
    const trimmed = [];
    for (let row = minRow; row <= maxRow; row++) {
      const newRow = [];
      for (let col = minCol; col <= maxCol; col++) {
        newRow.push(pieceGrid[row][col]);
      }
      trimmed.push(newRow);
    }

    return trimmed;
  };

  const addAnalysisStep = (step) => {
    setAnalysisSteps((prevSteps) => [...prevSteps, step]);
  };

  // Find a solution for the current board and pieces
  const findSolution = () => {
    // Get trimmed pieces from the editors
    const pieces = pieceEditors
      .map((editor) => trimPiece(editor))
      .filter((piece) => piece.some((row) => row.some((cell) => cell === 1)));

    if (!gameBoard || pieces.length === 0) {
      addAnalysisStep("Please create at least one piece before finding a solution.");
      return;
    }

    setIsSolving(true);
    setAnalysisSteps([]);
    addAnalysisStep("Finding solution...");

    // Allow UI to update before intensive computation
    setTimeout(() => {
      const solutionSteps = solveBoard(gameBoard, pieces);

      if (solutionSteps && solutionSteps.length > 0) {
        setSolution(solutionSteps);
        addAnalysisStep(`Found solution with ${solutionSteps.length} steps!`);
        setShowingSolution(true); // Automatically show solution
      } else {
        addAnalysisStep("No solution found with current pieces and board configuration.");
      }

      setIsSolving(false);
    }, 100);
  };

  const solveBoard = (board, availablePieces) => {
    // Clone the board and pieces to avoid modifying the originals
    const boardCopy = board.map((row) => [...row]);
    const piecesCopy = [...availablePieces];

    // Try to place the first piece in all possible positions and orientations
    const solutions = [];
    const initialStep = { board: boardCopy, piecesLeft: piecesCopy, moves: [] };

    // Begin depth-first search for solutions
    findSolutionDFS(initialStep, solutions);

    // Return the first solution found (if any)
    return solutions.length > 0 ? solutions[0].moves : null;
  };

  const findSolutionDFS = (state, solutions, depth = 0) => {
    // Limit search depth to avoid hanging the browser
    if (depth > 15 || solutions.length > 0) return;

    const { board, piecesLeft, moves } = state;

    // If no pieces left, we've found a solution
    if (piecesLeft.length === 0) {
      solutions.push({ moves: [...moves] });
      return;
    }

    // Try each remaining piece
    for (let pieceIndex = 0; pieceIndex < piecesLeft.length; pieceIndex++) {
      const piece = piecesLeft[pieceIndex];
      const remainingPieces = piecesLeft.filter((_, i) => i !== pieceIndex);

      // Try each possible rotation of the piece
      const rotations = getAllRotations(piece);

      for (let rotation of rotations) {
        // Try each possible position on the board
        for (let row = 0; row <= board.length - rotation.length; row++) {
          for (let col = 0; col <= board[0].length - rotation[0].length; col++) {
            // Check if piece can be placed here
            if (canPlacePiece(board, rotation, row, col)) {
              // Place the piece
              const newBoard = placeAndClearLines(board, rotation, row, col);

              // Find the original index of this piece in the editors
              const originalPieceIndex = pieceEditors.findIndex(
                (editor) => JSON.stringify(trimPiece(editor)) === JSON.stringify(piece)
              );

              // Add this move to our sequence
              const move = {
                pieceIndex: originalPieceIndex !== -1 ? originalPieceIndex : pieceIndex,
                rotation: rotation,
                position: { row, col },
              };

              // Continue searching with the updated board and remaining pieces
              findSolutionDFS(
                {
                  board: newBoard,
                  piecesLeft: remainingPieces,
                  moves: [...moves, move],
                },
                solutions,
                depth + 1
              );
            }
          }
        }
      }
    }
  };

  const getAllRotations = (piece) => {
    // For this simplified version, we return just the original piece
    // In a full implementation, we would generate all rotations and flips
    return [piece];
  };

  const canPlacePiece = (board, piece, startRow, startCol) => {
    // Check if the piece can be placed at the given position
    for (let row = 0; row < piece.length; row++) {
      for (let col = 0; col < piece[row].length; col++) {
        if (piece[row][col]) {
          const boardRow = startRow + row;
          const boardCol = startCol + col;

          // Check if out of bounds
          if (boardRow >= board.length || boardCol >= board[0].length) {
            return false;
          }

          // Check if cell is already occupied
          if (board[boardRow][boardCol]) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const placeAndClearLines = (board, piece, startRow, startCol) => {
    // Clone the board
    const newBoard = board.map((row) => [...row]);

    // Place the piece
    for (let row = 0; row < piece.length; row++) {
      for (let col = 0; col < piece[row].length; col++) {
        if (piece[row][col]) {
          newBoard[startRow + row][startCol + col] = 1;
        }
      }
    }

    // Check for completed rows
    for (let row = 0; row < newBoard.length; row++) {
      if (newBoard[row].every((cell) => cell === 1)) {
        // Clear this row
        for (let col = 0; col < newBoard[row].length; col++) {
          newBoard[row][col] = 0;
        }
      }
    }

    // Check for completed columns
    for (let col = 0; col < newBoard[0].length; col++) {
      let isColumnFull = true;
      for (let row = 0; row < newBoard.length; row++) {
        if (newBoard[row][col] === 0) {
          isColumnFull = false;
          break;
        }
      }

      if (isColumnFull) {
        // Clear this column
        for (let row = 0; row < newBoard.length; row++) {
          newBoard[row][col] = 0;
        }
      }
    }

    return newBoard;
  };

  // Reset the solution view
  const resetSolutionView = () => {
    setShowingSolution(false);
  };

  // Render the game board
  const renderGameBoard = () => {
    return (
      <div className="mx-auto w-full max-w-md">
        <div
          className="grid grid-cols-8 gap-1 border-2 border-indigo-900 bg-indigo-900 p-1"
          style={{ aspectRatio: "1 / 1" }}
        >
          {gameBoard.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
              // Determine if this cell is part of the solution highlights
              let highlightedPieceIndex = -1;

              if (showingSolution && solution) {
                // Check each solution step to see if this cell is highlighted
                for (let i = 0; i < solution.length; i++) {
                  const step = solution[i];
                  const piece = trimPiece(pieceEditors[step.pieceIndex]);
                  const { row: startRow, col: startCol } = step.position;

                  if (
                    rowIndex >= startRow &&
                    rowIndex < startRow + piece.length &&
                    colIndex >= startCol &&
                    colIndex < startCol + piece[0].length &&
                    piece[rowIndex - startRow][colIndex - startCol] === 1
                  ) {
                    highlightedPieceIndex = step.pieceIndex;
                    break;
                  }
                }
              }

              const colorClasses =
                highlightedPieceIndex >= 0 ? getColorClasses(highlightedPieceIndex) : null;

              // Determine cell background color based on state and highlight
              let cellBgClass = "bg-indigo-800"; // Default empty cell
              if (highlightedPieceIndex >= 0) {
                // This is a highlighted solution piece
                cellBgClass = colorClasses.bg;
              } else if (cell === 1) {
                // This is a filled cell but not part of a solution
                cellBgClass = "bg-gray-500";
              }

              return (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={`relative aspect-square transition-colors ${cellBgClass} ${
                    highlightedPieceIndex >= 0 ? `border-2 ${colorClasses.border}` : ""
                  }`}
                  onClick={() => toggleBoardCell(rowIndex, colIndex)}
                >
                  {/* Cell highlight effect for filled cells */}
                  {cell === 1 && highlightedPieceIndex === -1 && (
                    <div className="absolute left-0 top-0 h-1/3 w-1/3 bg-gray-400" />
                  )}

                  {/* Highlight effect for solution cells */}
                  {highlightedPieceIndex >= 0 && (
                    <div
                      className={`absolute left-0 top-0 h-1/3 w-1/3 ${colorClasses.highlight}`}
                    />
                  )}

                  {/* Debug coordinates */}
                  <div className="absolute left-0.5 top-0.5 text-[8px] text-white/50">
                    {rowIndex},{colIndex}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };

  // Render a piece editor
  const renderPieceEditor = (editorIndex) => {
    const editorGrid = pieceEditors[editorIndex];
    const colorClasses = getColorClasses(editorIndex);

    return (
      <div className="rounded-lg bg-gray-50 p-0 sm:border sm:border-gray-200 sm:p-4">
        <h3 className={`mb-2 text-lg font-semibold ${colorClasses.text}`}>
          Piece {editorIndex + 1}
        </h3>
        <p className="hidden text-sm text-gray-600 sm:block">
          Click on the grid to toggle cells and design your piece.
        </p>

        <div className="mb-4 flex justify-center">
          <div className="grid grid-cols-5 gap-0 bg-gray-300 p-0.5">
            {editorGrid.map((row, rowIndex) =>
              row.map((cell, colIndex) => (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={`h-6 w-6 cursor-pointer border border-gray-300 sm:h-8 sm:w-8 ${
                    cell === 1 ? colorClasses.bg : "bg-gray-100"
                  }`}
                  onClick={() => togglePieceEditorCell(editorIndex, rowIndex, colIndex)}
                >
                  {cell === 1 && <div className={`h-1/3 w-1/3 ${colorClasses.highlight}`} />}
                </div>
              ))
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={() => clearPieceEditor(editorIndex)}
            className={`rounded px-3 py-1 text-sm text-white sm:text-base ${colorClasses.button}`}
          >
            Clear
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
      <div className="space-y-6 sm:space-y-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold sm:text-4xl">Block Blast Solver</h1>
          <div className="mx-auto mt-4 max-w-3xl text-base text-gray-600 sm:mt-6 sm:text-lg">
            <p className="mb-4">
              Create your Block Blast puzzle by clicking on the board to toggle cells between empty
              and filled. Then define your available pieces and find the optimal solution.
            </p>
            <p className="hidden sm:block">
              The solver takes into account that filled rows and columns disappear, allowing for
              complex multi-step solutions.
            </p>
          </div>
        </div>

        <div className="rounded-lg border border-gray-200 bg-white p-2 sm:p-8">
          <div className="grid gap-6 lg:grid-cols-2 lg:gap-8">
            <div className="flex flex-col items-center space-y-6">
              <div className="w-full max-w-md">{renderGameBoard()}</div>

              {/* Piece editors section - single column on mobile, three columns on larger screens */}
              <div className="grid w-full grid-cols-3 space-y-0 sm:mt-6 sm:gap-4 sm:space-y-0">
                {[0, 1, 2].map((index) => (
                  <div key={index}>{renderPieceEditor(index)}</div>
                ))}
              </div>
            </div>

            <div className="flex flex-col">
              {gameBoard && (
                <div className="mb-4 rounded border border-gray-200 bg-gray-50 p-4 sm:mb-6">
                  <div className="flex items-center justify-between">
                    <h3 className="text-base font-semibold sm:text-lg">Solution</h3>
                    {!showingSolution ? (
                      <div className="flex space-x-4">
                        <button
                          onClick={resetBoard}
                          className="rounded bg-gray-500 px-2 py-1 text-sm text-white hover:bg-gray-300 disabled:opacity-50 sm:px-3"
                        >
                          Reset Board
                        </button>
                        <button
                          onClick={findSolution}
                          disabled={isSolving}
                          className="rounded bg-blue-500 px-2 py-1 text-sm text-white hover:bg-blue-600 disabled:opacity-50 sm:px-3"
                        >
                          {isSolving ? "Solving..." : "Find Solution"}
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={resetSolutionView}
                        className="rounded bg-gray-500 px-2 py-1 text-sm text-white hover:bg-gray-600 sm:px-3"
                      >
                        Reset View
                      </button>
                    )}
                  </div>

                  {solution ? (
                    <div className="mt-4">
                      <p className="mb-2 text-sm font-medium text-green-600 sm:text-base">
                        Solution found! Pieces placed in this order:
                      </p>

                      <ol className="list-decimal space-y-1 pl-5 text-sm sm:text-base">
                        {solution.map((step, index) => {
                          const colorClass = getColorClasses(step.pieceIndex).text;
                          return (
                            <li key={index} className={colorClass}>
                              Place piece {step.pieceIndex + 1} at position ({step.position.row},{" "}
                              {step.position.col})
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  ) : (
                    <p className="mt-4 text-sm text-gray-600 sm:text-base">
                      {isSolving
                        ? "Analyzing possible moves..."
                        : "Click 'Find Solution' to analyze possible piece placements."}
                    </p>
                  )}
                </div>
              )}

              <div className="mb-2 sm:mb-4">
                <h3 className="text-base font-semibold sm:text-lg">Analysis Steps</h3>
              </div>

              <div className="grow rounded border border-gray-200 bg-gray-50 p-4">
                {analysisSteps.length === 0 ? (
                  <div className="text-sm text-gray-400 sm:text-base">
                    Configure the board and pieces, then click &ldquo;Find Solution&rdquo;.
                  </div>
                ) : (
                  <ul className="space-y-2 text-sm sm:text-base">
                    {analysisSteps.map((step, index) => (
                      <li key={index} className="flex items-start">
                        <span className="mr-2 text-green-500">✓</span>
                        {step}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
