import { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

class Agent {
  constructor(x, y, angle) {
    this.x = x;
    this.y = y;
    this.angle = angle;
    this.speed = 1;
    this.sensorAngle = Math.PI / 4; // 45 degrees
    this.sensorDistance = 3;
  }

  sense(trailMap, width, height, sensorAngle) {
    const sensorX = Math.floor(this.x + Math.cos(this.angle + sensorAngle) * this.sensorDistance);
    const sensorY = Math.floor(this.y + Math.sin(this.angle + sensorAngle) * this.sensorDistance);

    // Wrap sensor coordinates
    const wrappedX = (sensorX + width) % width;
    const wrappedY = (sensorY + height) % height;

    return trailMap[wrappedY * width + wrappedX];
  }

  move(width, height, trailMap) {
    // Sense pheromone at three points
    const leftTrail = this.sense(trailMap, width, height, -this.sensorAngle);
    const centerTrail = this.sense(trailMap, width, height, 0);
    const rightTrail = this.sense(trailMap, width, height, this.sensorAngle);

    // Decide turning based on sensor readings
    if (centerTrail > leftTrail && centerTrail > rightTrail) {
      // Continue straight
      this.angle += (Math.random() - 0.5) * 0.1;
    } else if (leftTrail > rightTrail) {
      // Turn left
      this.angle -= Math.random() * 0.3;
    } else if (rightTrail > leftTrail) {
      // Turn right
      this.angle += Math.random() * 0.3;
    } else {
      // Random walk if no trail detected
      this.angle += (Math.random() - 0.5) * 0.5;
    }

    // Move in current direction
    this.x += Math.cos(this.angle) * this.speed;
    this.y += Math.sin(this.angle) * this.speed;

    // Wrap around edges
    this.x = (this.x + width) % width;
    this.y = (this.y + height) % height;
  }
}

export default function SlimeMoldSimulation({
  width = 800,
  height = 600,
  agentCount = 10000,
  isRunning = false,
}) {
  const canvasRef = useRef(null);
  const displayCanvasRef = useRef(null);
  const agentsRef = useRef([]);
  const baseWidth = useRef(400);
  const baseHeight = useRef(400);
  const trailMapRef = useRef(new Float32Array(baseWidth.current * baseHeight.current));
  const frameRef = useRef(null);
  const isInitializedRef = useRef(false);

  const renderFrame = useCallback(() => {
    const displayCanvas = displayCanvasRef.current;
    const ctx = displayCanvas.getContext("2d");

    // Create temporary canvas for base simulation size
    const baseCanvas = document.createElement("canvas");
    baseCanvas.width = baseWidth.current;
    baseCanvas.height = baseHeight.current;
    const baseCtx = baseCanvas.getContext("2d");
    const baseImageData = baseCtx.createImageData(baseWidth.current, baseHeight.current);

    // Render trails to base canvas
    for (let i = 0; i < trailMapRef.current.length; i++) {
      const value = Math.floor(trailMapRef.current[i] * 255);
      const i4 = i * 4;
      baseImageData.data[i4] = value; // R
      baseImageData.data[i4 + 1] = value; // G
      baseImageData.data[i4 + 2] = value; // B
      baseImageData.data[i4 + 3] = 255; // A
    }

    // Draw base image data
    baseCtx.putImageData(baseImageData, 0, 0);

    // Clear and scale to display canvas
    ctx.clearRect(0, 0, width, height);
    ctx.save();
    ctx.imageSmoothingEnabled = true;
    ctx.drawImage(baseCanvas, 0, 0, width, height);
    ctx.restore();
  }, [width, height, displayCanvasRef, baseWidth, baseHeight, trailMapRef]);

  const animate = useCallback(() => {
    if (!isRunning) return;

    // Update agents at base resolution
    agentsRef.current.forEach((agent) => {
      agent.move(baseWidth.current, baseHeight.current, trailMapRef.current);

      // Deposit pheromone
      const x = Math.floor(agent.x);
      const y = Math.floor(agent.y);
      const index = y * baseWidth.current + x;
      trailMapRef.current[index] = Math.min(1, trailMapRef.current[index] + 0.5);
    });

    // Diffuse and decay trail map
    const newTrailMap = new Float32Array(baseWidth.current * baseHeight.current);
    for (let y = 0; y < baseHeight.current; y++) {
      for (let x = 0; x < baseWidth.current; x++) {
        const idx = y * baseWidth.current + x;
        let sum = 0;
        let count = 0;

        // 3x3 diffusion kernel
        for (let dy = -1; dy <= 1; dy++) {
          for (let dx = -1; dx <= 1; dx++) {
            const nx = (x + dx + baseWidth.current) % baseWidth.current;
            const ny = (y + dy + baseHeight.current) % baseHeight.current;
            sum += trailMapRef.current[ny * baseWidth.current + nx];
            count++;
          }
        }

        // Diffuse and decay
        newTrailMap[idx] = Math.max(0, (sum / count) * 0.9);
      }
    }
    trailMapRef.current = newTrailMap;

    renderFrame();
    frameRef.current = requestAnimationFrame(animate);
  }, [isRunning, agentsRef, baseWidth, baseHeight, trailMapRef, renderFrame]);

  // Reset simulation when agent count changes
  useEffect(() => {
    const centerX = baseWidth.current / 2;
    const centerY = baseHeight.current / 2;
    const radius = Math.min(baseWidth.current, baseHeight.current) / 4;

    // Initialize or update agents
    agentsRef.current = Array(agentCount)
      .fill(0)
      .map((_, i) => {
        const angle = (i / agentCount) * Math.PI * 2;
        return new Agent(
          centerX + Math.cos(angle) * radius,
          centerY + Math.sin(angle) * radius,
          angle
        );
      });

    // Clear trail map
    trailMapRef.current = new Float32Array(baseWidth.current * baseHeight.current);

    // Force a render
    renderFrame();
  }, [agentCount, renderFrame]);

  // Initialize simulation
  useEffect(() => {
    if (!isInitializedRef.current) {
      isInitializedRef.current = true;
      renderFrame();
    }

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, [renderFrame]);

  // Handle running state changes
  useEffect(() => {
    if (isRunning) {
      animate();
    } else if (frameRef.current) {
      cancelAnimationFrame(frameRef.current);
    }

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, [isRunning, animate]);

  return (
    <div className="relative">
      <canvas
        ref={canvasRef}
        width={baseWidth.current}
        height={baseHeight.current}
        className="absolute inset-0 w-full rounded-lg"
        style={{ display: "none" }}
      />
      <canvas
        ref={displayCanvasRef}
        width={width}
        height={height}
        className="w-full rounded-lg bg-black"
      />
    </div>
  );
}

SlimeMoldSimulation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  agentCount: PropTypes.number,
  isRunning: PropTypes.bool,
};
