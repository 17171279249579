import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

export default function PageTransition({ children }) {
  const location = useLocation();

  return (
    <div key={location.pathname} className="relative">
      {children}
    </div>
  );
}

PageTransition.propTypes = {
  children: PropTypes.node.isRequired,
};
